export type Point = {
	x: number
	y: number
}

export function wiggle2d( translations: Array<Point>, amount: number ) : Array<Point> {
	for ( let i = 0; i < translations.length; i++ ) {
		translations[i].x = translations[i].x + ( Math.floor( ( ( Math.sin( Math.random() ) * 2 ) - 1 ) * amount ) );
		translations[i].y = translations[i].y + ( Math.floor( ( ( Math.sin( Math.random() ) * 2 ) - 1 ) * amount ) );
	}

	return translations;
}
