window.addEventListener( 'mousemove', () => {
	const hideTextElement = document.querySelector( 'p.l-landing__subtitle' ) as HTMLElement | null;
	if ( !hideTextElement ) {
		return;
	}

	hideTextElement.style.opacity = '1';

}, {
	once: true,
} );
