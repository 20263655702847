import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrOverlay extends MrModalDialog {
	#mobileTicker = 0;

	override firstFocusableElement(): HTMLElement | void {
		if ( !this.parentNode ) {
			return this;
		}

		const firstFocusableChild = this.querySelector( 'nav' );
		if ( firstFocusableChild && firstFocusableChild instanceof HTMLElement ) {
			firstFocusableChild.setAttribute( 'data-forced-focus', '' );

			const removeForcedFocus = () => {
				firstFocusableChild.removeAttribute( 'data-forced-focus' );
				firstFocusableChild.removeEventListener( 'blur', removeForcedFocus );
			};

			firstFocusableChild.addEventListener( 'blur', removeForcedFocus, {
				once: true,
			} );

			return firstFocusableChild;
		}

		return this;
	}

	override async willOpen() {
		await super.willOpen();

		document.body.classList.add( 'has-open-overlay' );

		this.querySelectorAll( 'img[loading="lazy"]' ).forEach( ( img ) => {
			img.removeAttribute( 'loading' );
		} );

		this.querySelectorAll( '[navigation-overlay-show-on-hover]' ).forEach( ( elem ) => {
			elem.setAttribute( 'hidden', '' );
		} );

		const activeFrame = this.querySelector( '[is-active-page]' );
		if ( activeFrame ) {
			activeFrame.removeAttribute( 'hidden' );
		}

		if ( matchMedia( '(max-width: 1025px)' ).matches ) {
			this.#mobileTicker = window.setInterval( () => {
				const mediaItems = Array.from( this.querySelectorAll( '[navigation-overlay-show-on-hover]' ) );
				if ( 1 >= mediaItems.length ) {
					window.clearInterval( this.#mobileTicker );

					return;
				}

				let currentVisibleItem = mediaItems.find( ( media ) => {
					return !media.hasAttribute( 'hidden' );
				} );

				if ( !currentVisibleItem ) {
					mediaItems[0].removeAttribute( 'hidden' );
					currentVisibleItem = mediaItems[0];
				}

				const currentVisibleItemIndex = mediaItems.indexOf( currentVisibleItem );

				mediaItems.forEach( ( media ) => {
					media.setAttribute( 'hidden', '' );
				} );

				if ( currentVisibleItemIndex < ( mediaItems.length - 1 ) ) {
					mediaItems[currentVisibleItemIndex + 1].removeAttribute( 'hidden' );
				} else {
					mediaItems[0].removeAttribute( 'hidden' );
				}
			}, 2000 );
		} else {
			this.querySelectorAll( '[navigation-overlay-trigger-on-hover]' ).forEach( ( media ) => {
				media.addEventListener( 'mouseenter', handleMouseEnterOnNavigationItem );
			} );
		}
	}

	override async willClose() {
		await super.willClose();

		document.body.classList.remove( 'has-open-overlay' );

		window.clearInterval( this.#mobileTicker );

		this.querySelectorAll( '[navigation-overlay-trigger-on-hover]' ).forEach( ( media ) => {
			media.removeEventListener( 'mouseenter', handleMouseEnterOnNavigationItem );
		} );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: 0,
					},
					{
						opacity: 1,
					},
				],
				{
					duration: 256,
					easing: 'ease-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__list' ),
				[
					{
						transform: 'perspective(30rem) translateZ(30rem)',
					},
					{
						transform: 'perspective(30rem) translateZ(0)',
					},
				],
				{
					duration: 512,
					easing: 'ease-out',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.frame-for-navigation-overlay' ),
				[
					{
						opacity: 0,
						filter: 'grayscale(1)',
					},
					{
						opacity: 1,
						filter: 'grayscale(0)',
					},
				],
				{
					delay: 256,
					duration: 512,
					easing: 'ease-out',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this.querySelector( '.frame-for-navigation-overlay' ),
				[
					{
						opacity: 1,
						filter: 'grayscale(1)',
					},
					{
						opacity: 0,
						filter: 'grayscale(0)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__list' ),
				[
					{
						transform: 'perspective(30rem) translateZ(0)',
					},
					{
						transform: 'perspective(30rem) translateZ(30rem)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: 1,
					},
					{
						opacity: 0,
					},
				],
				{
					delay: 128,
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-navigation-overlay', MrOverlay );

function handleMouseEnterOnNavigationItem( e: Event ) {
	if ( !e || !e.target ) {
		return;
	}

	const el = e.target;
	if ( !( 'getAttribute' in el ) ) {
		// Handled type check for runtime.
		return;
	}

	// Hard type check for typescript.
	targetIsHTMLElementLike( el );

	const hoverEffectTargetId = el.getAttribute( 'navigation-overlay-trigger-on-hover' );
	if ( !hoverEffectTargetId ) {
		return;
	}

	const hoverEffectTarget = document.querySelector( `[navigation-overlay-show-on-hover="${hoverEffectTargetId}"]` );
	if ( !hoverEffectTarget ) {
		return;
	}

	document.querySelectorAll( '[navigation-overlay-show-on-hover]' ).forEach( ( x ) => {
		x.setAttribute( 'hidden', '' );
	} );

	hoverEffectTarget.removeAttribute( 'hidden' );
}

function targetIsHTMLElementLike( target: EventTarget ): asserts target is HTMLElement {
	if ( !( 'getAttribute' in target ) ) {
		throw new Error( 'expected event target to be html element like' );
	}
}
