import { wiggle2d } from './helpers';

let throttle = 0;
let counter = 0;
const isMobileQuery = matchMedia( '(max-width: 1279px)' );
const prefersReducedMotion = matchMedia( '(prefers-reduced-motion: reduce)' );

const mobileTickerInterval = ( () => {
	if ( prefersReducedMotion.matches ) {
		return 1200;
	}

	return 256;
} )();

const desktopThrottleDelay = ( () => {
	if ( prefersReducedMotion.matches ) {
		return 512;
	}

	return 128;
} )();

let ticker = 0;

function mediaQueryChangeHandler(): void {
	window.clearInterval( ticker );
	if ( !isMobileQuery.matches ) {
		return;
	}

	let iterations = 0;

	const tickerFunc = () => {
		const allImages = Array.from( document.querySelectorAll( 'img.landing-paint__image' ) as NodeListOf<HTMLImageElement> );
		const nextImage = document.querySelector( 'img.landing-paint__image[not-painted]' ) as HTMLImageElement | null;
		if ( !nextImage ) {
			return;
		}

		const container = document.querySelector( 'div.landing-paint' ) as HTMLElement | null;
		if ( !container ) {
			return;
		}

		const containerRect = container.getBoundingClientRect();

		const nextImageIndex = allImages.indexOf( nextImage );
		if ( nextImageIndex >= ( allImages.length - 1 ) ) {
			allImages[0].setAttribute( 'not-painted', '' );
		} else {
			allImages[nextImageIndex + 1].setAttribute( 'not-painted', '' );
		}

		const nextLazy = document.querySelector( 'img.landing-paint__image[loading="lazy"]' ) as HTMLImageElement | null;
		if ( nextLazy ) {
			loadImage( nextLazy );
		}

		const point = wiggle2d( [
			{
				x: containerRect.width / 2,
				y: containerRect.height / 2,
			},
		], Math.min( containerRect.width / 5, containerRect.height / 5 ) )[0];

		nextImage.style.left = `${point.x}px`;
		nextImage.style.top = `${point.y}px`;
		nextImage.style.zIndex = `${counter}`;
		nextImage.removeAttribute( 'not-painted' );

		counter++;

		if ( 0.20 > Math.random() && 4 > iterations ) {
			requestAnimationFrame( () => {
				iterations++;
				tickerFunc();
			} );
		} else {
			iterations = 0;
		}
	};

	ticker = window.setInterval( tickerFunc, mobileTickerInterval );
}


function mouseMoveHandler( e: MouseEvent ): void {
	if ( 0 !== throttle ) {
		return;
	}

	// always disable automatic paint when a cursor event happens.
	window.clearInterval( ticker );

	requestAnimationFrame( () => {
		const allImages = Array.from( document.querySelectorAll( 'img.landing-paint__image' ) as NodeListOf<HTMLImageElement> );
		const nextImage = document.querySelector( 'img.landing-paint__image[not-painted]' ) as HTMLImageElement | null;
		if ( !nextImage ) {
			return;
		}

		const nextImageIndex = allImages.indexOf( nextImage );
		if ( nextImageIndex >= ( allImages.length - 1 ) ) {
			allImages[0].setAttribute( 'not-painted', '' );
		} else {
			allImages[nextImageIndex + 1].setAttribute( 'not-painted', '' );
		}

		const nextLazy = document.querySelector( 'img.landing-paint__image[loading="lazy"]' ) as HTMLImageElement | null;
		if ( nextLazy ) {
			loadImage( nextLazy );
		}

		nextImage.style.left = `${e.clientX}px`;
		nextImage.style.top = `${e.clientY}px`;
		nextImage.style.zIndex = `${counter}`;
		nextImage.removeAttribute( 'not-painted' );

		counter++;
	} );

	throttle = window.setTimeout( () => {
		throttle = 0;
	}, desktopThrottleDelay );
}

function initLandingPaint() {
	if ( !document.body ) {
		return;
	}

	// EXPERIMENTAL FEATURE
	if ( matchMedia( '(prefers-reduced-data: reduce)' ).matches ) {
		return;
	}

	const landingPaintImages = document.querySelectorAll( 'img.landing-paint__image' ) as NodeListOf<HTMLImageElement>;
	if ( !landingPaintImages || 0 === landingPaintImages.length ) {
		return;
	}

	const landingPaint = document.querySelector( 'div.landing-paint' ) as HTMLElement | null;
	if ( !landingPaint ) {
		return;
	}

	landingPaint.parentElement?.removeEventListener( 'mousemove', mouseMoveHandler );
	landingPaint.parentElement?.addEventListener( 'mousemove', mouseMoveHandler, {
		passive: true,
	} );

	isMobileQuery.removeListener( mediaQueryChangeHandler );
	isMobileQuery.addListener( mediaQueryChangeHandler );

	Array.from( landingPaintImages ).slice( 0, 5 ).forEach( ( x ) => {
		loadImage( x );
	} );
}

initLandingPaint();

requestAnimationFrame( () => {
	initLandingPaint();
} );

window.addEventListener( 'load', () => {
	initLandingPaint();
	mediaQueryChangeHandler();
}, {
	once: true,
} );

function loadImage( img: HTMLImageElement ): void {
	img.removeAttribute( 'loading' );

	if ( img.hasAttribute( 'lazy-srcset' ) ) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		img.setAttribute( 'srcset', img.getAttribute( 'lazy-srcset' )! );
		img.removeAttribute( 'lazy-srcset' );
	}

	if ( img.hasAttribute( 'lazy-src' ) ) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		img.setAttribute( 'src', img.getAttribute( 'lazy-src' )! );
		img.removeAttribute( 'lazy-src' );
	}

	const picture = img.parentElement;
	if ( picture && 'PICTURE' === picture.tagName ) {
		picture.querySelectorAll( 'source' ).forEach( ( source ) => {
			if ( source.hasAttribute( 'lazy-srcset' ) ) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				source.setAttribute( 'srcset', source.getAttribute( 'lazy-srcset' )! );
				source.removeAttribute( 'lazy-srcset' );
			}
		} );
	}
}
